import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { lighten } from '@mui/material/styles';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';

export default function TestimonialTemplate({parsedData, index}: OfferProps ) {
  let amount = parsedData.Amount.replace('$', '')
  let processedAmount = parseInt(amount);
  
  
  return (
      
        <Grow in={true} timeout={1000}>
        <Grid
        key={index}
        container
        id="testimonial-content-box"
        //spacing={4}
        //columnSpacing={4}
        sx={{
          marginBottom:'5px',
          padding:{xs:'10px',md:'20px'},
          borderRadius:'10px',
          maxWidth:'800px',
          minHeight:600,
          boxShadow:"rgba(0, 0, 0, 0.35) 0px 5px 15px;",
          background:'linear-gradient(#080808, #080808) padding-box, linear-gradient(to right, #02B63E, #5E6C24, #0150B5) border-box',
          border:'2px solid transparent'
          //border:"1px solid white" 
        }}
        >
          <Grid xs={12} md={4.5}
          sx={{
            textAlign:'left',padding:'10px',whiteSpace:'nowrap'}}>
            
             <Box
              component="img"
              //src={parsedData.avatarImage + '/photo/'}
              src={'https://unavatar.io/twitter/' + parsedData.Handle.replace('@','')}
              sx={{
                width:'40px',
                display:'inline-block',
                borderRadius:'25px'
                
              }}
              >

              </Box>
            
            
              <Box sx={{
                display:'inline-block',
                verticalAlign:'top'}}>
                <Typography sx={{
                textTransform:'uppercase', 
                fontWeight:'900', 
                fontSize:'1rem', 
                opacity:'1', 
                color:'white', 
                letterSpacing:'.9px',
                whiteSpace:'nowrap',
                marginLeft:'10px',
                //display:'inline'
               }}>

                  {parsedData.Handle}
                </Typography>
                <Typography sx={{
                
                fontWeight:'400', 
                fontSize:{xs: '.65rem', md:'.68rem'}, 
                opacity:'.8', 
                color:'white', 
                letterSpacing:'.9px',
                whiteSpace:'nowrap',
                marginLeft:'10px'
               }}>

                  {parsedData.Date}
                </Typography>
                </Box>
                <Typography sx={{
                
                fontWeight:'700', 
                fontSize:{xs: '1.5rem'}, 
                opacity:'1', 
                color:'#31FF52', 
                letterSpacing:'.9px',
                whiteSpace:'nowrap',
                marginBottom:'10px'
               }}>

                  💵 {parsedData.Amount}
                </Typography>
                <Typography sx={{
                
                fontWeight:'300', 
                fontSize:{xs: '1.1rem'}, 
                opacity:'1', 
                color:'white',
                whiteSpace:'wrap'
               }}>

                  💵 {parsedData.Text}
                </Typography>
            

          </Grid>
          <Grid xs={12} md={7.5}
          sx={{
            padding:'10px'}}>
          <Box
              component="img"
              //src={parsedData.avatarImage + '/photo/'}
              src={parsedData.tweetImage}
              sx={{
                maxWidth:'100%',
                width:'auto',
                maxHeight:'500px'
                
              }}
              >

              </Box>
          </Grid>
        </Grid>
        </Grow>
        
    )
}