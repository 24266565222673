import React, { useState } from "react";
import Papa, { ParseResult } from "papaparse"
import ls from 'localstorage-slim';
import './MuiClassNameSetup';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TestimonialTemplate from './TestimonialTemplate';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';
import MuiToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";
import IconButton from '@mui/material/IconButton';

export default function RenderOffers() {
    

  const ToggleButton = styled(MuiToggleButton)({
    color:'white',
    borderRadius:'10px',
    border:'1px solid white',
    padding:'10px',
    fontSize:'.8rem',
    "&.Mui-selected, &.Mui-selected:hover": {
      color: "white",
      backgroundColor: '#005CCE',
    }

  });

    const [parsedCsvData, setParsedCsvData] = React.useState<Values | undefined>();
    const [offerPointer, setOfferPointer] = useState(0);

    const [sort, setSort] = React.useState('amount');

    const handleChange = (
      event: React.MouseEvent<HTMLElement>,
      newSort: string,
    ) => {
      if (newSort === null) {
        newSort = sort
      } else {
      setSort(newSort);
      if (newSort === 'date') {
        setOfferPointer(Math.floor(Math.random() * 4))
      } else {
        setOfferPointer(0)
      }
    }
    };
      
    
    const showPrev = (numOffers:number) => {
      if (offerPointer <= 0) {
        setOfferPointer(numOffers - 1)
      } else {
        setOfferPointer(offerPointer - 1)
      }

    }

    const showNext = (numOffers:number) => {
      if (offerPointer < numOffers-1) {
        setOfferPointer(offerPointer+1);
      } else {
        setOfferPointer(0)
      }
      //setOfferPointer(Math.min(offerPointer + 1, 4))

    }
    
    const amountSort = (a:Data,b:Data) => {
      return parseFloat(b.Amount.split('$')[1].replace(/,/g, '')) - parseFloat(a.Amount.split('$')[1].replace(/,/g, ''))
    }

    const dateSort = (a:Data,b:Data) => {
      return new Date(b.Date).valueOf() - new Date(a.Date).valueOf()
    }
    

    React.useEffect(() => {
        const url = process.env.REACT_APP_SHEETS_URL!;

        const getCSV = () => {
            Papa.parse(url, {
            header: true,
            download: true,
            skipEmptyLines: true,
            delimiter: ",",
            complete: (results: ParseResult<Data>) => {
                setParsedCsvData(results)
                cacheTestimonialData(results);
            },
            })
        }
        const cacheTestimonialData = (testimonialData:Papa.ParseResult<Data>) => {
          const THREEDAYS = 259200;
    
          ls.set('testimonialData', JSON.stringify(testimonialData), { ttl: THREEDAYS });
        }
        
        const cachedState = ls.get('testimonialData');
        
        if (cachedState) {
          setParsedCsvData(JSON.parse(String(cachedState)))
        } else {
          getCSV()

        }
        
        
        
    }, [])


    const buttonRef = React.useRef<HTMLInputElement>(null)

    /*React.useEffect(() => {
      setInterval(() => {
        buttonRef.current!.click()
      }, 19000)
    }, [])*/
    
    
return (
    <Box id="testimonial-bounding-box" 
        sx={{ 
            minWidth: 120,
            maxWidth: 800,
            padding:"10px",
            marginLeft:{xs:0,md:'35px'},
            marginBottom:'20px',
            position:'relative'
            }}>
        <Typography sx={{
               
              fontWeight:'700', 
              fontFamily:'Roboto',
              fontSize:{xs:'1.2rem',md:'30px'},
              color:'white', 
              marginTop:'3px',
              marginBottom:'5px',
              textAlign:'left'}}>

                Our Users Win All. The. Time.
              </Typography>
              <Typography sx={{
               
               fontWeight:'400', 
               fontSize:'.9rem',
               color:'white', 
               marginTop:'3px',
               marginBottom:'20px',
               textAlign:'left'}}>
 
                 These are <Box component="span" fontWeight='700' display='inline'>real testimonials</Box> from <Box component="span" fontWeight='700' display='inline'>real DFS players</Box> using Stokastic Tools. You could be one of them!
               </Typography>    
               <Stack 
                direction={{xs:'column',md:"column"}}
                alignItems={{xs:'left',md:'left'}}
                justifyContent="space-between">
                <ToggleButtonGroup
                    color="primary"
                    
                    value={sort}
                    exclusive
                    onChange={handleChange}
                    aria-label="Sort By"
                    sx={{
                      textAlign:'left',
                      marginBottom:'20px',
                      display:'block'
                      
                    }}
                  >
                    <ToggleButton sx={{borderRight:0}} value="amount">Big Wins</ToggleButton>
                    <ToggleButton sx={{borderLeft:0}} value="date">Most Recent</ToggleButton>
                  </ToggleButtonGroup>
                  <Typography sx={{
                    fontFamily:'Roboto',
                    fontWeight:'700', 
                    fontSize:{xs:'1.3rem',md:'30px'},
                    marginBottom:'20px',
                    color:'white', 
                    textAlign:{xs:'left',md:'left'}
                    }}>
 
                 Viewing {offerPointer +1} of {parsedCsvData?.data.length} Stokastic user win testimonials
               </Typography>    
                </Stack>
        
        {parsedCsvData && 
        parsedCsvData.data
        //.filter((parsedData) => parsedData.State.toLowerCase() === state.toLowerCase())
        .sort(sort === 'amount' ? amountSort : dateSort)
        //.slice(0,offerCount)
        .map((parsedData, index) => 
        
        
        offerPointer === index && <TestimonialTemplate parsedData={parsedData} index={index}/>
        
        )
        }
        {
        parsedCsvData && 
      
        <span>
          <IconButton 
                  sx={{
                    
                    
                    position:'absolute',
                    //transform:{md:'translate(-452px,-340px)'},
                    backgroundColor:'#232323',
                    left:{xs:-7,md:-41},
                    top:{xs:550,md:400},
                    color:'white',
                    borderRadius:'50px'
                  }}
                  size='large'
                  disableRipple
                  
                  onClick={() => {
                    showPrev(parsedCsvData.data.length);
                  }}>
              <KeyboardArrowLeftIcon/>
          </IconButton>
        </span>

            }
        {
          parsedCsvData && 
        
        <span ref={buttonRef} onClick={() => {
          showNext(parsedCsvData.data.length);
        }}>
          <IconButton 
                sx={{
                  fontWeight:'bold',
                  fontSize:'.65rem',
                  position:'absolute',
                  right:{xs:-7,md:-41},
                  top:{xs:550,md:400},
                  //transform:{md:'translate(403px,-340px)'},
                  backgroundColor:'#015ACE',
                  color:'white',
                  borderRadius:'50px'
                }}
                disableRipple
                size='large'
                
                >
            <KeyboardArrowRightIcon/>
          </IconButton>
          </span>

              }
    </Box>

);

}